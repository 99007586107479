import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame,useLoader,useThree } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial,RenderTexture, Image, Text, Environment,useGLTF,OrbitControls } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import { TextureLoader } from 'three/src/loaders/TextureLoader'


import WasdControls from './WasdControls'
const GOLDENRATIO = 1.61803398875

export const App = ({ images }) => {

    const orbitControls = useRef()
    const [introState, setIntroState] = useState(true);
    const [timeoutCompleted, setTimeoutCompleted] = useState(false); // Add state to track timeout completion
    const colorMap = useLoader(TextureLoader, 'pebbles.jpeg')

    useEffect(() => {
        setTimeout( () => {
        setIntroState(false)
        console.log('disable intro state');
      
const targetPosition = new THREE.Vector3();
orbitControls.current.object.getWorldDirection(targetPosition);
targetPosition.multiplyScalar(1).add(orbitControls.current.object.position);
orbitControls.current.target.copy(targetPosition);
        } ,1500)
    },[]);



    return (
  <Canvas dpr={[1, 1.5]} >
      <color attach="background" args={['#191920']} />
    <fog attach="fog" args={['#191920', 0, 15]} />

    <CameraRig position={[0, 1.6, 5]} fov={70} />
    <OrbitControls ref={orbitControls}
      minPolarAngle={Math.PI / 2 - .3}
      maxPolarAngle={Math.PI - Math.PI / 2}
      enableZoom={false}
      enabled={!introState}
    />
    <WasdControls orbitControls={orbitControls} />

    <group position={[0, -0.7, 1]}>
      <Frames intro={introState} images={images} />
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={50}
          roughness={1}
          depthScale={2.3}
          minDepthThreshold={0.3}
          maxDepthThreshold={1.1}
          color="#050505"
          metalness={0.5}
          distortion={0.02}
          distortionMap={colorMap} // The red channel of this texture is used as the distortion map. Default is null
        />
      </mesh>
    </group>
    <Environment preset="city" />
  </Canvas>
    )
}



function CameraRig(props) {
  const ref = useRef()
  const { camera } = useThree()

  useEffect(() => {
    camera.position.copy(ref.current.position)
    camera.rotation.order = ref.current.rotation.order
    camera.rotation.y = ref.current.rotation.y
    camera.rotation.x = ref.current.rotation.x
    camera.rotation.z = ref.current.rotation.z
  }, [camera])

  useFrame(() => ref.current.updateMatrixWorld())

  return <perspectiveCamera ref={ref} {...props} />
}

export const Second = () => {
    return (<>
        <color attach="background" args={['#cc7b32']} />
          <fog attach="fog" args={['#cc7b32', 0, 200]} />
    <Model />
    </>
    )
}




function Model({ ready }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/scene-draco.glb')
  useFrame(() => (group.current.rotation.y += 0.001))
  return (
    <group ref={group} scale={0.001} position={[0, 0, -100]} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-102253.52, -210688.86, -17050.52]}>
          <mesh material={materials.Scene_Root} geometry={nodes.mesh_0.geometry} />
          <mesh material={materials.Scene_Root} geometry={nodes.mesh_1.geometry} />
          <mesh material={materials.Scene_Root} geometry={nodes.mesh_2.geometry} />
          <mesh material={materials.Scene_Root} geometry={nodes.mesh_3.geometry} />
        </group>
        <group position={[100000, 120000, 2000]}>

        </group>
      </group>
    </group>
  )
}


function Frames({ images,intro, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation();
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 7.5)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    if(intro){
        easing.damp3(state.camera.position, p, 0.4, dt)
        easing.dampQ(state.camera.quaternion, q, 0.4, dt)
    }
  })
  return (
    <group
      ref={ref}
      // onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const image = useRef()
  const frame = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  useCursor(hovered)
  useFrame((state, dt) => {
    image.current.material.zoom = 2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    easing.damp3(image.current.scale, [0.85 * (!isActive && hovered ? 0.85 : 1), 0.9 * (!isActive && hovered ? 0.905 : 1), 1], 0.1, dt)
    easing.dampC(frame.current.material.color, hovered ? 'orange' : 'white', 0.1, dt)
  })
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial color={'#fffdcc'} toneMapped={false} fog={false} >
 
        </meshBasicMaterial>
        </mesh>

        <mesh ref={frame} raycast={() => null} scale={[0.87, 0.91, 0.8]} position={[0, 0, 0.3]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} >
          <RenderTexture width={512} height={512 *  GOLDENRATIO} attach="map" anisotropy={16}>
            <Second/>
          </RenderTexture>
        </meshBasicMaterial>
        </mesh>

        <Image raycast={() => null} ref={image} position={[0, 430, 0.7]} url={url} />
      </mesh>
      {/* <Text maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, GOLDENRATIO, 0]} fontSize={0.025}>
        {name.split('-').join(' ')}
      </Text> */}
    </group>
  )
}
