import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'


const images = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0], url: '/spiral.jpeg' },

  { position: [1.5, 0, 1.8], rotation: [0, -0.4, 0], url:'/pebbles.jpeg' },
  { position: [-1.5, 0, 1.8], rotation: [0, 0.4, 0], url:'/anna.png' }
]

createRoot(document.getElementById('root')).render(<App images={images} />)
